import React from "react"
import styled from "styled-components"
import { Link, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CallToAction from "../components/CallToAction"
import { BreadCrumb, LeftPanel, MainPanel, MarkdownContent, RightPanel, Section } from "../components/Section"
import TimeBackground from "../images/creat-time.png"
import generateHTML from "../utils/generateHTML"
import { SidebarCard, SidebarCardBody, SidebarCardHeader, SidebarCardTitle, SidebarForm, SidebarFormBody, SidebarFormHeader, SidebarFormTitle, SidebarSticky } from "../components/Sidebar"
import { Badge, ListBadge, ListItemBadge, Media, MediaBody, MediaList, MediaThumb } from "../components/ListGroup"
import QuickContactForm from "../components/QuickContactForm"

const CreatTime = styled.div`
  position: relative;
  width: fit-content;
  background: url(${(props) => props.bg}) top right;
  background-size: cover;
  padding: 6px 15px 6px 10px;
  margin-bottom: -15px;
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
  color: white;
  z-index: 20;
`

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
`

const InfographicTemplate = ({ data, location, formName }) => {
  const {
    contentfulInfographic: infographic,
    allContentfulInfographic: { edges: totalInfographics },
    allContentfulInfographicCategory: { edges: categories }
  } = data;
  const recentPosts = totalInfographics.slice(0, 3);

  const infographicCount = (categoryName) => {
    let count = 0;
    totalInfographics.forEach(item => {
      if (item.node.category.name === categoryName) {
        count++;
      }
    });
    return count;
  }

  return (
    <Layout location={location}>
      <SEO
        title={infographic.metaTitle}
        description={infographic.metaDescription}
      />
      <Section pt="200px" mtp="170px" pb="60px" bg="#fff">
        <div className="container">
          <TitleWrapper>
            <h1>{infographic.title}</h1>
            <BreadCrumb className="static">
              <Link to="/">Home</Link> / <Link to="/infographics/">Infographics</Link> / <span>{infographic.title}</span>
            </BreadCrumb>
          </TitleWrapper>
          <MainPanel>
            <LeftPanel>
              <CreatTime bg={TimeBackground}>{infographic.createdAt}</CreatTime>
              <Img
                fluid={infographic.image.fluid}
                alt={infographic.title}
              />
              <h3>{infographic.category.name}</h3>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: generateHTML(infographic.content.content),
                }}
              />
            </LeftPanel>
            <RightPanel>
              <SidebarSticky className="sidebar isSticky">
                <SidebarCard>
                  <SidebarCardHeader><SidebarCardTitle>Recent Posts</SidebarCardTitle></SidebarCardHeader>
                  <SidebarCardBody>
                    <MediaList className="media-list">
                      {recentPosts.map((post, i) => {
                        const temp = post.node.title.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ")
                        const titleUrl = temp.split(" ").join("-").toLowerCase()
                        const url = titleUrl.slice(-1) === "-" ? titleUrl.slice(0, -1) : titleUrl;
                        return (
                          <Media className="media" key={i}>
                            <MediaThumb className="media-thumb">
                              <Img
                                fluid={post.node.thumbnail.fluid}
                                alt={`thumbnail-${post.node.title}`}
                              />
                            </MediaThumb>
                            <MediaBody className="media-body">
                              <p><Link to={`/infographics/${url}/`}>{post.node.title}</Link></p>
                              <small>{post.node.createdAt}</small>
                            </MediaBody>
                          </Media>
                        )
                      })}
                    </MediaList>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarCard>
                  <SidebarCardHeader><SidebarCardTitle>Infographic Categories</SidebarCardTitle></SidebarCardHeader>
                  <SidebarCardBody>
                    <ListBadge className="list-badge">
                      <ListItemBadge className="list-item">
                        <Link to="/infographics/">
                          <Badge bg="white" color="#140F0F">
                            All Infographics <span>{totalInfographics.length}</span>
                          </Badge>
                        </Link>
                      </ListItemBadge>

                      {categories.map((category, i) => (
                        <ListItemBadge className="list-item" key={i}>
                          <Badge
                            bg="white"
                            color="#140F0F"
                            onClick={() => navigate("/infographics/", { state: { category: category.node.name } })}
                          >
                            <span className='tag'>{category.node.name}</span>
                            <span className='count'>{infographicCount(category.node.name)}</span>
                          </Badge>
                        </ListItemBadge>
                      ))}
                    </ListBadge>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarForm>
                  <SidebarFormHeader><SidebarFormTitle>GET IN TOUCH</SidebarFormTitle></SidebarFormHeader>
                  <SidebarFormBody>
                    <QuickContactForm location={location} formName={formName ? formName : "Quick Infographic Form"} />
                  </SidebarFormBody>
                </SidebarForm>
              </SidebarSticky>
            </RightPanel>
          </MainPanel>
        </div>
      </Section>
      <CallToAction />
    </Layout>
  )
}

export default InfographicTemplate

export const pageQuery = graphql`
  query infographicQuery($id: String!) {
    contentfulInfographic(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      image {
        fluid(maxWidth: 3000, quality: 100, resizingBehavior: NO_CHANGE) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      category {
        name
      }
      createdAt(formatString: "D MMM YYYY")
      content {
        content
      }
    }
    allContentfulInfographic {
	    edges {
	      node {
	        title
	        thumbnail {
	          fluid(maxWidth: 2500) {
	            aspectRatio
	            base64
	            sizes
	            src
	            srcSet
	            srcSetWebp
	            srcWebp
	          }
	        }
	        description {
	          description
	        }
	        category {
	        	name
	        }
	        createdAt(formatString: "D MMM YYYY")
	      }
	    }
	  }
	  allContentfulInfographicCategory {
	    edges {
	      node {
	        name
	      }
	    }
	  }
  }
`
